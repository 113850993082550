<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column">
      <v-text-field
        :label="$t('t.AuthUrl')"
        v-model="computedAuthUrl"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        :label="$t('t.Url')"
        v-model="computedBaseUrl"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        class="field"
        :label="$t('t.Username')"
        v-model="computedUsername"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        class="field"
        :label="$t('t.Password')"
        v-model="computedPassword"
        :type="'password'"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        class="field"
        :label="$t('t.SenderDisplay')"
        v-model="computedSenderDisplay"
        :clearable="true"
        :rules="ruleMax200"
      />
      <v-text-field
        class="field"
        :label="$t('t.SenderPhoneNumber')"
        v-model="computedSenderPhoneNumber"
        :clearable="true"
        :rules="ruleMax200"
      />
    </div>
    <v-btn
      :disabled="!canTest"
      class="connect-btn mt-2"
      rounded
      justify-center
      color="primary"
      @click="testConnect()"
      v-ripple
      :loading="testConnectWorking"
    >
      <span>{{$t('t.TestConnection')}}</span>
    </v-btn>
    <v-bottom-sheet
      v-model="showError"
      persistent
      v-if="error"
      :hide-overlay="false"
    >
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          color="error"
          @click="closeError()"
        >
          {{$t('t.Ok')}}
        </v-btn>
        <div class="py-3 h6">
          {{error.title}}
        </div>
        <div class="py-3 body-2">
          {{error.message}}
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>

</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      testConnectWorking: false,
      error: null,
      showError: false,
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataAuthUrl: null,
      dataBaseUrl: null,
      dataPassword: null,
      dataUsername: null,
      dataSenderDisplay: null,
      dataSenderPhoneNumber: null,
      passwordChanged: false
    }
  },
  computed: {
    canTest () {
      if (!this.dataBaseUrl) { return false }
      // if (this.isDirty) { return false }

      return true
    },
    ruleRequiredMax200 () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 200
      ]
    },
    ruleMax200 () {
      return [v => (v ?? '').length <= 200]
    },
    computedAuthUrl: {
      get () {
        return this.dataAuthUrl
      },
      set (v) {
        this.dataAuthUrl = v
        this.dataDocumentEmit()
      }
    },
    computedBaseUrl: {
      get () {
        return this.dataBaseUrl
      },
      set (v) {
        this.dataBaseUrl = v
        this.dataDocumentEmit()
      }
    },
    computedSenderDisplay: {
      get () {
        return this.dataSenderDisplay
      },
      set (v) {
        this.dataSenderDisplay = v
        this.dataDocumentEmit()
      }
    },
    computedSenderPhoneNumber: {
      get () {
        return this.dataSenderPhoneNumber
      },
      set (v) {
        this.dataSenderPhoneNumber = v
        this.dataDocumentEmit()
      }
    },
    computedUsername: {
      get () {
        return this.dataUsername
      },
      set (v) {
        this.dataUsername = v
        this.dataDocumentEmit()
      }
    },
    computedPassword: {
      get () {
        return this.dataPassword
      },
      set (v) {
        this.passwordChanged = this.dataPassword !== v
        this.dataPassword = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'cesar',
        v: {
          providerId: this.dataProviderId,
          encryptKey: this.dataEncryptKey,
          authUrl: this.dataAuthUrl,
          baseUrl: this.dataBaseUrl,
          senderDisplay: this.dataSenderDisplay,
          senderPhoneNumber: this.dataSenderPhoneNumber,
          password: this.dataPassword,
          username: this.dataUsername,
          passwordChanged: this.passwordChanged
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async testConnect () {
      this.testConnectWorking = true

      const s = this.document.v

      await this.$http().post('/core/providers/cesar/test', s)
        .then(result => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.TestConnection') }))
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.Snackbar.TestConnectionError'))
        })
        .finally(e => {
          this.testConnectWorking = false
        })
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    document: Object
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.dataProviderId = v?.providerId
        this.dataEncryptKey = v?.encryptKey
        this.dataAuthUrl = v?.authUrl
        this.dataBaseUrl = v?.baseUrl
        this.dataSenderDisplay = v?.senderDisplay
        this.dataSenderPhoneNumber = v?.senderPhoneNumber
        this.dataPassword = v?.password
        this.dataUsername = v?.username
      }
    },
    isDirty: {
      immediate: true,
      handler (v) {
        if (!v) { this.passwordChanged = false }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.field
  max-width 30%

.connect-btn
  max-width 300px
</style>
